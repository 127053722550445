<template>
    <modal :open="slackModal" @close="$emit('closeModal')" @save="$emit('closeModal')" :modalData="modalData">
      <template v-slot:modalContent>
        <v-row class="ma-0 pa-0">
          <v-col cols="12">
            <template v-if="slackHistoryLoading">
              <v-skeleton-loader v-for="(item, i) in [1,2,3,4]" :key="i"
                type="list-item-avatar-three-line,divider"
              ></v-skeleton-loader>
            </template>
            <v-list three-line v-if="!slackHistoryLoading && historyMsgLists.length">
              <template v-for="(item, index) in historyMsgLists">
                <v-list-item :key="index" >
                  <v-list-item-avatar>
                    <v-img :src="historyUserDetails[item.user].profile.image_512"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="text-capitalize">{{historyUserDetails[item.user].real_name}}</v-list-item-title>
                    <v-list-item-subtitle>{{item.text}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider :key="item.ts"></v-divider>
              </template>
            </v-list>
            <div class="ma-0 pa-5 pb-7 text-center" v-if="!slackHistoryLoading && historyMsgLists.length == 0">
                No Slack History!.
            </div>
          </v-col>
        </v-row>
      </template>
    </modal>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
    props: ['slackModal'],
    emits: ['closeModal'],
    components:{
        Modal: () => import("@/components/common/modal"),
    },
    data() {
      return {
        modalData: {
            title: 'Slack History',
            saveBtnTxt: 'ok',
            width: 1000,
            maxHeight: '500px'
        },
        historyMsgLists: [],
        historyUserDetails: {},
        items: [
           {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          title: 'Brunch this weekend?',
          subtitle: `<span class="text--primary">Ali Connors</span> &mdash; I'll be in your neighborhood doing errands this weekend. Do you want to hang out?`,
        },
        ]
      }
    },
    computed: {
        ...mapGetters(['slackHistoryLoading', 'slackHistoryLists']),
    },
    watch: {
      slackHistoryLists: function (newValue) {
        const slackMsg = newValue.messages && newValue.messages.length ? newValue.messages : [],
          slackUser = !_.isEmpty(newValue.users) ? newValue.users : {};
        this.historyMsgLists =  slackMsg;
        this.historyUserDetails = slackUser;
      }
    },
    created() {
        const slackMsg = this.slackHistoryLists.messages && this.slackHistoryLists.messages.length ? this.slackHistoryLists.messages : [],
          slackUser = !_.isEmpty(this.slackHistoryLists.users) ? this.slackHistoryLists.users : {};
      this.historyMsgLists = slackMsg
      this.historyUserDetails = slackUser;
    }
}
</script>
